
export const yearGroups = [
    {name: "Year 1", year: 1},
    {name: "Year 2", year: 2},
    {name: "Year 3", year: 3},
    {name: "Year 4", year: 4},
    {name: "Year 5", year: 5},
    {name: "Year 6", year: 6},
    {name: "Year 7", year: 7},
    {name: "Year 8", year: 8},
    {name: "Year 9", year: 9},
    {name: "Year 10", year: 10},
    {name: "Year 11", year: 11}
];

export const typesOfIntervention = [
    {name: "Specialist", type: 'specialist'},
    {name: "Targeted", type: 'targeted'},
    {name: "Class Intervention", type: 'class_intervention'},
    {name: "No Intervention", type: 'no_intervention'}
];

export const attendanceIndicators = [
    {name: "Normal", status: 'normal'},
    {name: "Low", status: 'low'}
];

export const studentStatuses = [
    {name: "Inactive - Not Started", status: 'inactive_not_started'},
    {name: "Inactive - Intervention Completed", status: 'inactive_finished'},
    {name: "Inactive - Intervention Not Completed", status: 'inactive_not_completed_left'},
    {name: "Active - In School", status: 'active_in_school'},
    {name: "Active - Off Site", status: 'active_off_site'},
    {name: "Active - Home Educated", status: 'active_home_educated'}
];

export const userRoles = [
    {name: "Super Admin", role: 'super_admin'},
    {name: "SLT Admin", role: 'slt_member'},
    {name: "Tutor Group Lead", role: 'tutor'},
];

export const selectableSubjects = [
    {name: "Maths - All Areas", subject: 'maths_all_areas'},
    {name: "Maths - Number Areas Only", subject: 'maths_main_areas'},
];

export const mainAreasOfMaths = [
    'number', 'operations', 'fractions-decimals-percentages'
];